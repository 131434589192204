import {
  localizationLanguageRepository,
  localizationRepository
} from "@/feature/localization/di/LocalizationComponent";
import { AvailableLanguageModel } from "@/feature/localization/domain/model/AvailableLanguageModel";
import { LanguageModel } from "@/feature/localization/domain/model/LanguageModel";
import { LocalizationModel } from "@/feature/localization/domain/model/LocalizationModel";
import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { groupBy } from "@/infrastructure/utility/MapUtil";
import { useState } from "react";

export type LocalizationUiState = {
  languages: LanguageModel[] | null;
  availableLanguages: AvailableLanguageModel[] | null;
  localizations: Record<string, LocalizationModel[]> | null;
  searchQuery: string;
};

export type LocalizationUiAction = {
  getAvailableLanguages: () => void;
  getLanguages: () => void;
  getLocalizations: () => void;
  search: (searchQuery: string) => void;
  updateLocalization: (key: string, value: string, languageCode: string) => void;
  addLocalization: (key: string) => void;
  deleteLocalization: (key: string) => void;
  addLanguage: (code: string) => void;
  publish: () => void;
};

export type LocalizationViewModel = {
  uiState: LocalizationUiState;
  uiAction: LocalizationUiAction;
};

export const useLocalizationViewModel = () => {
  const [uiState, setUiState] = useState({} as LocalizationUiState);
  const { toast } = useToast();

  const [unFilterLocalization, setUnFilterLocalization] = useState<Record<string, LocalizationModel[]> | null>(null);

  async function getLanguages() {
    const result: ResultModel<LanguageModel[]> = await localizationLanguageRepository.getLanguages();

    if (result.onSuccess) {
      const list = result.onSuccess.sort((language1, language2) => {
        const code1 = language1.code.toUpperCase();
        const code2 = language2.code.toUpperCase();
        if (code1 > code2) return 1;
        if (code1 < code2) return -1;
        return 0;
      });
      setUiState((prevState) => {
        return {
          ...prevState,
          languages: list
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          languages: []
        };
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getAvailableLanguages() {
    const result: ResultModel<AvailableLanguageModel[]> = await localizationLanguageRepository.getAvailableLanguages();

    if (result.onSuccess) {
      setUiState((prevState) => {
        return {
          ...prevState,
          availableLanguages: result.onSuccess ?? []
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          availableLanguages: []
        };
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getLocalizations() {
    const result: ResultModel<LocalizationModel[]> = await localizationRepository.getLocalizations();

    if (result.onSuccess) {
      const list = result.onSuccess.sort((localization1, localization2) => {
        const code1 = localization1.languageCode.toUpperCase();
        const code2 = localization2.languageCode.toUpperCase();
        if (code1 > code2) return 1;
        if (code1 < code2) return -1;
        return 0;
      });
      const pair = groupBy(list, (localization) => {
        return localization.key;
      });
      setUnFilterLocalization(pair);
      setUiState((prevState) => {
        return {
          ...prevState,
          localizations: pair
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          localizations: null
        };
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function publish() {
    const result: ResultModel<any> = await localizationRepository.publish();

    if (result.onSuccess) {
      toast({ description: "Localization keys and languages has been published successfully", variant: "default" });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function addLanguage(code: string) {
    const result: ResultModel<any> = await localizationLanguageRepository.addLanguage(code);

    if (result.onSuccess) {
      getLanguages();
      getLocalizations();
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function addLocalization(key: string) {
    const result: ResultModel<any> = await localizationRepository.addLocalization(key);

    if (result.onSuccess) {
      getLocalizations();
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function deleteLocalization(key: string) {
    const result: ResultModel<any> = await localizationRepository.deleteLocalization(key);

    if (result.onSuccess) {
      getLocalizations();
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateLocalization(key: string, value: string, languageCode: string) {
    const result: ResultModel<any> = await localizationRepository.updateLocalization(key, value, languageCode);

    if (result.onSuccess) {
      getLocalizations();
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  function search(searchQuery: string) {
    if (!searchQuery) {
      setUiState((prevState) => {
        return {
          ...prevState,
          searchQuery: searchQuery,
          localizations: unFilterLocalization
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          searchQuery: searchQuery,
          localizations: filterRecordByKey(uiState.localizations ?? {}, searchQuery)
        };
      });
    }
  }

  function filterRecordByKey(
    record: Record<string, LocalizationModel[]>,
    keyToFilterBy: string
  ): Record<string, LocalizationModel[]> {
    const filteredRecord: Record<string, LocalizationModel[]> = {};
    Object.keys(record).forEach((key) => {
      if (key.toLowerCase().includes(keyToFilterBy)) {
        filteredRecord[key] = record[key];
      }
    });
    return filteredRecord;
  }

  return {
    uiState: uiState,
    uiAction: {
      getAvailableLanguages,
      getLanguages,
      getLocalizations,
      search,
      deleteLocalization,
      updateLocalization,
      addLocalization,
      addLanguage,
      publish
    }
  } as LocalizationViewModel;
};
