import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import moment from "moment";
import { useEffect, useState } from "react";
import { projectRepository } from "../di/ProjectComponent";
import { ProjectUsageModel } from "../domain/model/ProjectUsageModel";

type ProjectUsageUiState = {
  projectUsagesTotal: ProjectUsageModel[];
  projectUsagesRoute: ProjectUsageModel[];
  projectUsagesDevelopment: boolean;
  projectId?: string;
  fromDate: string;
  toDate: string;
  totalInstallCount: number;
  totalUsageCount: number;
  dataRangeTitle: string;
};

export const useProjectUsageViewModel = () => {
  const defaultProjectUsageUiState: ProjectUsageUiState = {
    projectUsagesTotal: [],
    projectUsagesRoute: [],
    projectUsagesDevelopment: true,
    totalInstallCount: 0,
    totalUsageCount: 0,
    dataRangeTitle: "In last 7 days",
    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment().subtract(1, "days").format("YYYY-MM-DD")
  };

  const [uiState, setUiState] = useState(defaultProjectUsageUiState);
  const { toast } = useToast();

  const updateUiState = (newState: Partial<ProjectUsageUiState>) => {
    setUiState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  useEffect(() => {
    if (uiState.projectId != null) getProjectUsages(uiState.projectId);
  }, [uiState.projectUsagesDevelopment]);

  useEffect(() => {
    if (uiState.projectId != null) getProjectUsages(uiState.projectId);
  }, [uiState.fromDate, uiState.toDate]);

  async function getProjectUsages(projectId: string) {
    const resultTotal: ResultModel<ProjectUsageModel[]> = await projectRepository.getProjectUsageByTotal(
      projectId,
      uiState.fromDate,
      uiState.toDate,
      uiState.projectUsagesDevelopment
    );
    const resultRoute: ResultModel<ProjectUsageModel[]> = await projectRepository.getProjectUsageByRoute(
      projectId,
      uiState.fromDate,
      uiState.toDate,
      uiState.projectUsagesDevelopment
    );

    updateUiState({
      projectId: projectId
    });
    if (resultRoute.onSuccess?.[0]) {
      let totalInstallCount = 0;
      let totalUsageCount = 0;
      resultTotal.onSuccess?.forEach((element) => {
        totalInstallCount += element.uniqueInstallCount;
        totalUsageCount += element.totalInstallCount;
      });
      updateUiState({
        totalInstallCount: totalInstallCount,
        totalUsageCount: totalUsageCount,
        projectUsagesRoute: resultRoute.onSuccess
      });
    } else {
      updateUiState({
        projectUsagesRoute: [],
        totalInstallCount: 0,
        totalUsageCount: 0
      });
    }

    if (resultTotal.onSuccess?.[0]) {
      updateUiState({
        projectUsagesTotal: resultTotal.onSuccess
      });
    } else {
      updateUiState({
        projectUsagesTotal: []
      });
    }

    if (resultTotal.onError) {
      toast({ description: resultTotal.onError ?? "", variant: "destructive" });
    }

    if (resultRoute.onError) {
      toast({ description: resultTotal.onError ?? "", variant: "destructive" });
    }
  }

  async function changeProjectUsagesDevelopment(developmentMode: boolean) {
    updateUiState({
      projectUsagesDevelopment: developmentMode
    });
  }

  async function changeTimeFrameRange(value: number) {
    updateUiState({
      dataRangeTitle: "In last " + value + " days",
      fromDate: moment().subtract(value, "days").format("YYYY-MM-DD"),
      toDate: moment().subtract(1, "days").format("YYYY-MM-DD")
    });
  }

  return {
    uiState: uiState,
    uiAction: {
      getProjectUsages,
      changeProjectUsagesDevelopment,
      changeTimeFrameRange
    }
  };
};
