import { Alert, AlertDescription } from "@/infrastructure/uikit/components/ui/alert";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Card } from "@/infrastructure/uikit/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { ArrowUpCircle, Box, MoreVertical, Pencil, Plus, Trash2 } from "lucide-react";
import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ActionIntegrationModel, ActionTriggerEventModel } from "../../domain/model/ActionModel";

export const TriggerNodeType = (props: any) => {
  const [updateName, setUpdateName] = useState("");
  const [{ isDragging }, drag] = useDrag({
    type: "TriggerNodeType",
    item: () => {
      return {
        id: props.trigger.id
      };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    end: (item, monitor) => {
      const dropResult: any = monitor.getDropResult();
      if (item && dropResult) {
        props.updateActionTriggerPosition(item.id ?? "", dropResult.id ?? "", dropResult.then ?? "NEXT");
      }
    }
  });

  const events = props.trigger.events.filter((event: ActionTriggerEventModel) => {
    return event.event !== "END";
  });

  let nodeColor = "bg-card";
  switch (props.trigger.then) {
    case "SUCCESS":
      nodeColor = "bg-green-200";
      break;
    case "FAILURE":
      nodeColor = "bg-red-200";
      break;
    case "NEXT":
      nodeColor = "bg-card";
      break;
    default:
      break;
  }

  const isSelected = props.activeTrigger?.id === props.trigger.id;

  const integration = props.integrationList.find((it: ActionIntegrationModel) => {
    return it.keyType === props.trigger.keyType;
  });

  return (
    <div
      ref={drag}
      className={`px-4 py-2 shadow-md rounded-md bg-card border-2 flex flex-col h-28 min-w-64 ${nodeColor} ${
        isSelected ? "border-card-foreground" : "border-muted"
      }`}
    >
      {(integration?.version ?? 0) > props.trigger.integrationVersion ? (
        <>
          <div className="h-1.5 w-1.5 rounded-full bg-destructive"></div>
        </>
      ) : (
        <></>
      )}
      <div className="flex flex-row justify-end">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <MoreVertical className="h-4 w-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <Dialog>
              <DialogTrigger asChild>
                <DropdownMenuItem
                  onSelect={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Trash2 className="w-4 h-4 mx-2" />
                  Delete {props.trigger.name}
                </DropdownMenuItem>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>{`Are you sure to delete the '${props.trigger.name}'?`}</DialogTitle>
                  <DialogDescription>
                    <div className="flex flex-col space-y-4 pt-4">
                      <div className="flex flex-col gap-2">
                        <Alert variant="destructive">
                          <AlertDescription>Please note that this action cannot be undone.</AlertDescription>
                        </Alert>
                      </div>
                    </div>
                  </DialogDescription>
                </DialogHeader>
                <DialogClose asChild={true}>
                  <Button
                    onClick={() => {
                      props.deleteActionTrigger(props.trigger.id ?? "");
                    }}
                    variant={"destructive"}
                    className="w-full"
                  >
                    Delete
                  </Button>
                </DialogClose>
              </DialogContent>
            </Dialog>
            <Dialog>
              <DialogTrigger asChild>
                <DropdownMenuItem
                  onSelect={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Pencil className="w-4 h-4 mx-2" />
                  Rename {props.trigger.name}
                </DropdownMenuItem>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>{`Do you want to rename '${props.trigger.name}'`}</DialogTitle>
                  <DialogDescription>
                    <div className="flex flex-col space-y-4 pt-4">
                      <Label className="sr-only" htmlFor="name">
                        Name
                      </Label>
                      <Input
                        id="name"
                        placeholder="name"
                        type="text"
                        autoCapitalize="none"
                        autoComplete="off"
                        autoCorrect="off"
                        onChange={(event: any) => setUpdateName(event.target.value)}
                      />
                    </div>
                  </DialogDescription>
                </DialogHeader>
                <DialogClose asChild={true}>
                  <Button
                    onClick={() => {
                      props.updateActionTriggerName(props.trigger.id ?? "", updateName);
                    }}
                    className="w-full"
                  >
                    Update
                  </Button>
                </DialogClose>
              </DialogContent>
            </Dialog>

            {(integration?.version ?? 0) > props.trigger.integrationVersion ? (
              <DropdownMenuItem
                onSelect={() => {
                  props.upgradeActionTrigger(props.trigger.id ?? "", integration?.id);
                }}
              >
                <ArrowUpCircle className="w-4 h-4 mx-2" />
                Upgrade {props.trigger.name}
              </DropdownMenuItem>
            ) : (
              <></>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <span
        onClick={() => {
          props.selectActionTrigger(props.trigger);
        }}
        className="text-sm text-card-foreground cursor-pointer"
      >
        {props.name}
      </span>
      <div className="flex flex-row gap-4 w-full justify-center mt-2">
        {events?.map((event: ActionTriggerEventModel) => {
          return (
            <AddTriggerDialog
              key={Math.random()}
              event={event}
              trigger={props.trigger}
              installedActions={props.installedActions}
              addActionTrigger={props.addActionTrigger}
            />
          );
        })}
      </div>
    </div>
  );
};

const AddTriggerDialog = (props: any) => {
  const [name, setName] = useState("");
  const [selectedIntegration, setSelectedIntegration] = useState<ActionIntegrationModel | null>(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "TriggerNodeType",
    drop: () => {
      return {
        id: props.trigger.id,
        then: props.event.event ?? "NEXT"
      };
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  let nodeColor = "bg-card-foreground";
  switch (props.event.event) {
    case "SUCCESS":
      nodeColor = "bg-green-500 hover:bg-green-600";
      break;
    case "FAILURE":
      nodeColor = "bg-red-500 hover:bg-red-600";
      break;
    case "NEXT":
      nodeColor = "bg-card-foreground";
      break;
    default:
      break;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button ref={drop} variant={"default"} size="icon" className={`rounded-full ${nodeColor}`}>
          <Plus className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className={"h-2/3"}>
        <DialogHeader>
          <DialogTitle>Add a new trigger</DialogTitle>
          <DialogDescription>Provide a name for the action</DialogDescription>
        </DialogHeader>
        <div>
          <Label className="sr-only" htmlFor="name">
            Name
          </Label>
          <Input
            id="name"
            placeholder="Name for trigger"
            type="text"
            autoComplete="off"
            autoCapitalize="none"
            autoCorrect="off"
            onChange={(event: any) => setName(event.target.value)}
          />
        </div>
        <ScrollArea className="w-full">
          <div className="grid grid-cols-2 gap-4">
            {props.installedActions?.map((integration: ActionIntegrationModel) => {
              return (
                <Card
                  key={integration.id}
                  className={`${integration.id === selectedIntegration?.id ? "border-muted-foreground" : ""}`}
                  onClick={() => {
                    setSelectedIntegration(integration);
                  }}
                >
                  <div className={"flex flex-row p-2 justify-start items-center gap-4"}>
                    <Box className="h-8 w-8 my-4" />
                    {integration.name}
                  </div>
                </Card>
              );
            })}
          </div>
        </ScrollArea>
        <DialogClose asChild={true}>
          <Button
            onClick={() => {
              props.addActionTrigger(selectedIntegration?.id ?? "", name, props.trigger.id ?? "", props.event.event);
              setSelectedIntegration(null);
            }}
            disabled={!name}
            variant={"default"}
            autoFocus
            className="w-full"
          >
            Add
          </Button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};
