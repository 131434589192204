import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { quoteRepository } from "@/plugin/quote/di/QuoteComponent";
import { QuoteModel } from "@/plugin/quote/domain/QuoteModel";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authRepository } from "../di/AuthComponent";
import { AuthUserModel } from "../domain/AuthUserModel";
import { getAnalytics, logEvent } from "firebase/analytics";

export const userAuthLoginViewModel = () => {
  const analytics = getAnalytics();

  const navigate = useNavigate();
  const { toast } = useToast();

  const [randomQuote, setRandomQuote] = useState({} as QuoteModel);
  useEffect(() => {
    const quotes = quoteRepository.getQuotes();
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setRandomQuote(quotes[randomIndex]);
  }, []);

  const login = async (email: string, password: string) => {
    const result: ResultModel<AuthUserModel> = await authRepository.authLogin(email, password);
    if (result.onSuccess?.id) {
      if (email === "yc-demo@nativeblocks.io") {
        logEvent(analytics, "yc_login");
      }
      navigate("/");
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  };

  const loginWithGoogle = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const loginWithGoogle = await signInWithPopup(auth, provider);
      const idToken = await loginWithGoogle.user.getIdTokenResult();
      const result: ResultModel<AuthUserModel> = await authRepository.authLoginWithGoogle(idToken.token ?? "");
      if (result.onSuccess?.id) {
        logEvent(analytics, "login", { name: `${loginWithGoogle.user.email} logged in successfully` });
        navigate("/");
      } else {
        toast({ description: result.onError ?? "", variant: "destructive" });
      }
    } catch (error: any) {
      toast({ description: "Login process was not successful please try again", variant: "destructive" });
    }
  };

  const getAuthToken = () => {
    return authRepository.getAuthToken();
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return {
    uiState: {
      todayQuote: randomQuote
    },
    uiAction: {
      login,
      loginWithGoogle,
      logout,
      getAuthToken
    }
  };
};
