import { gql } from "@apollo/client";

export const CREATE_PROJECT_MUTATION = gql`
    mutation createProject($input: CreateProjectInput!) {
        createProject(input: $input) {
            id
        }
    }
`;

export const DELETE_PROJECT_MUTATION = gql`
    mutation deleteProject($input: DeleteProjectInput!) {
        deleteProject(input: $input) {
            id
        }
    }
`;

export const UPDATE_PROJECT_MUTATION = gql`
    mutation updateProject($input: UpdateProjectInput!) {
        updateProject(input: $input) {
            id
        }
    }
`;

export const GET_PROJECTS_QUERY = gql`
    query projects($organizationId: String!) {
        projects(organizationId: $organizationId) {
            id
            name
            platform
            apiKeys {
                name
                apiKey
                expireAt
            }
            organization {
                id
            }
        }
    }
`;

export const GET_PROJECT_USAGE_BY_TOTAL_QUERY = gql`
    query projectUsageByTotal($projectId: String!, $from: String!, $to: String!, $developmentMode: Boolean!) {
        projectUsageByTotal(id: $projectId, from: $from, to: $to, developmentMode: $developmentMode) {
            text
            uniqueInstallCount
            totalInstallCount
        }
    }
`;

export const GET_PROJECT_USAGE_BY_ROUTE_QUERY = gql`
    query projectUsageByRoute($projectId: String!, $from: String!, $to: String!, $developmentMode: Boolean!) {
        projectUsageByRoute(id: $projectId, from: $from, to: $to, developmentMode: $developmentMode) {
            text
            uniqueInstallCount
            totalInstallCount
        }
    }
`;

export const GET_PROJECT_API_KEY_QUERY = gql`
    query projectApiKeys($projectId: String!) {
        projectApiKeys(projectId: $projectId) {
            name
            apiKey
            expireAt
        }
    }
`;
export const ADD_PROJECT_API_KEY_MUTATION = gql`
    mutation addProjectApiKey($input: AddProjectApiKeyInput!) {
        addProjectApiKey(input: $input) {
            name
            apiKey
        }
    }
`;

export const DELETE_PROJECT_API_KEY_MUTATION = gql`
    mutation deleteProjectApiKey($input: DeleteProjectApiKeyInput!) {
        deleteProjectApiKey(input: $input) {
            name
            apiKey
        }
    }
`;
