import { userAuthLoginViewModel } from "@/feature/auth/login/presentation/AuthLoginViewModel";
import { OrganizationView } from "@/feature/organization/presentation/OrganizationView";
import { useOrganizationViewModel } from "@/feature/organization/presentation/OrganizationViewModel";
import { ProjectView } from "@/feature/project/presentation/ProjectView";
import { AppEnvironmentConfig } from "@/infrastructure/network/EnvironmentProvider";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import { Separator } from "@/infrastructure/uikit/components/ui/separator";
import { Copy, LogOut, QrCode } from "lucide-react";
import React, { FC, useState } from "react";

export const DashboardView: FC = () => {
  const [currentOrganization, setCurrentOrganization] = useState("");
  const organizationViewModel = useOrganizationViewModel();
  const authLoginViewModel = userAuthLoginViewModel();

  return (
    <div className="flex flex-row w-full h-full">
      <div className="flex flex-col h-full justify-between">
        <OrganizationView
          organizationViewModel={organizationViewModel}
          onSelect={(organizationId: string) => {
            setCurrentOrganization(organizationId);
          }}
        ></OrganizationView>
        <div className="p-4">
          <Dialog>
            <DialogTrigger className="w-full">
              <Button variant="ghost" className="w-full justify-start">
                <QrCode className="mr-2 h-4 w-4" /> Link device
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Link account</DialogTitle>
                <DialogDescription>Credential for compiler plugin</DialogDescription>
                <div className="flex flex-col w-full justify-end items-end py-8">
                  <Button
                    className="absolute"
                    onClick={() => {
                      const credential = JSON.stringify({
                        endpoint: AppEnvironmentConfig.API_ENDPOINT_GRAPHQL_URL,
                        authToken: authLoginViewModel.uiAction.getAuthToken(),
                        organizationId: organizationViewModel.uiState.currentOrganization?.id ?? ""
                      });
                      navigator.clipboard.writeText(credential);
                    }}
                    variant={"ghost"}
                    size={"icon"}
                  >
                    <Copy />
                  </Button>
                  <p className="p-4 w-full">
                    "endpoint": "******"
                    <br />
                    "authToken": "******"
                    <br />
                    "organizationId": "******"
                  </p>
                </div>
              </DialogHeader>
            </DialogContent>
          </Dialog>
          <Button
            onClick={() => {
              authLoginViewModel.uiAction.logout();
            }}
            variant="ghost"
            className="w-full justify-start"
          >
            <LogOut className="mr-2 h-4 w-4" /> Logout
          </Button>
          <Separator />
        </div>
      </div>
      <ProjectView organizationId={currentOrganization}></ProjectView>
    </div>
  );
};
