import { LocalizationRepository } from "@/feature/localization/domain/repository/LocalizationRepository";
import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { LocalizationModel } from "../domain/model/LocalizationModel";
import {
  ADD_LOCALIZATION_MUTATION,
  DELETE_LOCALIZATION_MUTATION,
  GET_LOCALIZATIONS_QUERY,
  PUBLISH_LOCALIZATION_MUTATION,
  UPDATE_LOCALIZATION_MUTATION
} from "@/feature/localization/data/LocalizationQuery";

export class LocalizationRepositoryImpl implements LocalizationRepository {

  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async getLocalizations(): Promise<ResultModel<LocalizationModel[]>> {
    try {
      const result = await this.graphqlClient.query({
        query: GET_LOCALIZATIONS_QUERY
      });
      const list: LocalizationModel[] = result?.data?.localizations.localizations.map((item: any) => {
        return this.toLocalizationModel(item);
      });
      return <ResultModel<LocalizationModel[]>>{
        onSuccess: list
      };
    } catch (error: any) {
      return <ResultModel<LocalizationModel[]>>{
        onError: error.message
      };
    }
  }

  async addLocalization(key: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: ADD_LOCALIZATION_MUTATION,
        variables: {
          input: {
            key: key
          }
        }
      });

      return {
        onSuccess: {}
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<string>;
    }
  }

  async updateLocalization(key: string, value: string, languageCode: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: UPDATE_LOCALIZATION_MUTATION,
        variables: {
          input: {
            key: key,
            value: value,
            languageCode: languageCode
          }
        }
      });

      return {
        onSuccess: {}
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<string>;
    }
  }

  async deleteLocalization(key: string): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: DELETE_LOCALIZATION_MUTATION,
        variables: {
          input: {
            key: key
          }
        }
      });

      return {
        onSuccess: {}
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<string>;
    }
  }

  async publish(): Promise<ResultModel<any>> {
    try {
      await this.graphqlClient.mutate({
        mutation: PUBLISH_LOCALIZATION_MUTATION
      });
      return {
        onSuccess: {}
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<string>;
    }
  }

  toLocalizationModel(item: any): LocalizationModel {
    return {
      id: item.id ?? "",
      key: item.key ?? "",
      value: item.value ?? "",
      languageCode: item.languageCode ?? "",
      languageName: item.languageName ?? ""
    } as LocalizationModel;
  }
}