import { WorkspaceViewModel } from "@/coordinator/workspace/WorkspaceViewModel";
import { BlockIntegrationModel } from "@/feature/block/domain/model/BlockModel";
import { BlockHierarchyView } from "@/feature/block/presentation/BlockView";
import { BlockViewModel } from "@/feature/block/presentation/BlockViewModel";
import { FrameView } from "@/feature/frame/presentation/FrameView";
import { FrameViewModel } from "@/feature/frame/presentation/FrameViewModel";
import { IntegrationViewModel } from "@/feature/integration/presentation/IntegrationViewModel";
import { LocalizationViewModel } from "@/feature/localization/presentation/LocalizationViewModel";
import { VariableViewModel } from "@/feature/variable/presentation/VariableViewModel";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Folder, Languages, ListTree, Settings, Shapes, Table } from "lucide-react";
import React, { FC } from "react";

type LeftSidePanelProps = {
  workspaceViewModel: WorkspaceViewModel;
  frameViewModel: FrameViewModel;
  blockViewModel: BlockViewModel;
  variableViewModel: VariableViewModel;
  integrationViewModel: IntegrationViewModel;
  localizationViewModel: LocalizationViewModel;
};

export const LeftSidePanel: FC<LeftSidePanelProps> = (props: LeftSidePanelProps) => {
  return (
    <>
      <div className="flex flex-col space-y-2">
        <Button
          onClick={() => {
            props.workspaceViewModel.uiAction.selectLeftPanel("FRAME");
          }}
          variant={props.workspaceViewModel.uiState.leftPanel === "FRAME" ? "secondary" : "ghost"}
          size="icon"
        >
          <Folder className="h-4 w-4" />
        </Button>
        <Button
          disabled={!props.frameViewModel.uiState.selectedFrame}
          onClick={() => {
            props.workspaceViewModel.uiAction.selectLeftPanel("BLOCK_HIERARCHY");
          }}
          variant={props.workspaceViewModel.uiState.leftPanel === "BLOCK_HIERARCHY" ? "secondary" : "ghost"}
          size="icon"
        >
          <ListTree className="h-4 w-4" />
        </Button>
        <Button
          onClick={() => {
            props.workspaceViewModel.uiAction.openVariable(props.frameViewModel.uiState.selectedFrame?.id ?? "");
          }}
          disabled={!props.frameViewModel.uiState.selectedFrame}
          variant={"ghost"}
          size="icon"
        >
          <Table className="h-4 w-4" />
        </Button>
        <Button
          onClick={() => {
            props.workspaceViewModel.uiAction.openIntegration();
          }}
          variant={"ghost"}
          size="icon"
        >
          <Shapes className="h-4 w-4" />
        </Button>
        <Button
          onClick={() => {
            props.workspaceViewModel.uiAction.openLocalization();
          }}
          variant={"ghost"}
          size="icon"
        >
          <Languages className="h-4 w-4" />
        </Button>
        <Button
          onClick={() => {
            props.workspaceViewModel.uiAction.openSetting(props.frameViewModel.uiState.scaffold?.projectId ?? "");
          }}
          variant={"ghost"}
          size="icon"
        >
          <Settings className="h-4 w-4" />
        </Button>
      </div>
      <LeftSidePanelContent
        frameViewModel={props.frameViewModel}
        blockViewModel={props.blockViewModel}
        integrationViewModel={props.integrationViewModel}
        activePanel={props.workspaceViewModel.uiState.leftPanel}
        onFrameSelect={() => {
          props.workspaceViewModel.uiAction.selectLeftPanel("BLOCK_HIERARCHY");
          props.blockViewModel.uiAction.getBlock(props.frameViewModel.uiState.selectedFrame?.id ?? "", "");
        }}
        onDeleteFrame={() => {
          props.blockViewModel.uiAction.getBlock("", "");
        }}
        onBlockSelect={() => {
          props.workspaceViewModel.uiAction.selectRightPanel("BLOCK_PROPERTY");
        }}
        onAddBlock={(key) => {
          props.variableViewModel.uiAction.addVariable(
            props.frameViewModel.uiState.selectedFrame?.id ?? "",
            key + "-visibility",
            "true",
            "BOOLEAN"
          );
        }}
        onDeleteBlock={(key) => {
          props.variableViewModel.uiAction.deleteVariable(
            props.frameViewModel.uiState.selectedFrame?.id ?? "",
            key + "-visibility"
          );
        }}
        onDuplicateBlock={(key) => {
          props.variableViewModel.uiAction.addVariable(
            props.frameViewModel.uiState.selectedFrame?.id ?? "",
            key + "-visibility",
            "true",
            "BOOLEAN"
          );
        }}
        onUpdateBlockKey={(key: string, newKey: string) => {
          props.variableViewModel.uiAction.deleteVariable(
            props.frameViewModel.uiState.selectedFrame?.id ?? "",
            key + "-visibility"
          );
          props.variableViewModel.uiAction.addVariable(
            props.frameViewModel.uiState.selectedFrame?.id ?? "",
            newKey + "-visibility",
            "true",
            "BOOLEAN"
          );
        }}
      />
    </>
  );
};

type LeftSidePanelContentProps = {
  frameViewModel: FrameViewModel;
  blockViewModel: BlockViewModel;
  integrationViewModel: IntegrationViewModel;
  onFrameSelect: () => void;
  onDeleteFrame: () => void;
  onBlockSelect: () => void;
  onAddBlock: (key: string) => void;
  onDeleteBlock: (key: string) => void;
  onDuplicateBlock: (key: string) => void;
  onUpdateBlockKey: (key: string, newKey: string) => void;
  activePanel: string | null;
};

const LeftSidePanelContent: FC<LeftSidePanelContentProps> = (props: LeftSidePanelContentProps) => {
  if (props.activePanel === "FRAME") {
    return (
      <FrameView
        onFrameSelect={props.onFrameSelect}
        onDeleteFrame={props.onDeleteFrame}
        frameViewModel={props.frameViewModel}
      />
    );
  } else if (props.activePanel === "BLOCK_HIERARCHY") {
    return (
      <BlockHierarchyView
        onBlockSelect={props.onBlockSelect}
        blockViewModel={props.blockViewModel}
        installedBlocks={
          props.integrationViewModel.uiState.blockIntegrationsInstalled
            ?.filter((integration) => {
              return !integration.integrationDeprecated;
            })
            .map((integration) => {
              return {
                id: integration.integrationId,
                name: integration.integrationName,
                keyType: integration.integrationKeyType,
                version: integration.integrationVersion
              } as BlockIntegrationModel;
            }) ?? []
        }
        frameId={props.frameViewModel.uiState.selectedFrame?.id ?? ""}
        onAddBlock={props.onAddBlock}
        onDeleteBlock={props.onDeleteBlock}
        onDuplicateBlock={props.onDuplicateBlock}
        onUpdateBlockKey={props.onUpdateBlockKey}
      />
    );
  } else {
    return <></>;
  }
};
