import { actionRepository } from "@/feature/action/di/ActionComponent";
import { ActionModel, ActionTriggerModel } from "@/feature/action/domain/model/ActionModel";
import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export type ActionViewModel = {
  uiState: ActionUiState;
  uiAction: {
    getActions(frameId: string): void;
    selectAction(frameId: string, blockKey: string, activeEvent: string, action?: ActionModel | null): void;
    deleteAction(frameId: string, actionId: string): void;
    addAction(frameId: string, key: string, event: string): void;
    addActionTrigger(frameId: string, integrationId: string, name: string, parentId: string | null, then: string): void;
    deleteActionTrigger(frameId: string, triggerId: string): void;
    updateActionTriggerProperties(frameId: string, changeMap: Map<string, string>): void;
    getActionTrigger(frameId: string, triggerId: string): void;
    upgradeActionTrigger(frameId: string, actionTriggerId: string, integrationId: string): void;
    updateActionTriggerPosition(frameId: string, actionTriggerId: string, parentId: string, then: string): void;
    updateActionTriggerName(frameId: string, actionTriggerId: string, name: string): void;
    updateActionTriggerData(frameId: string, actionTriggerId: string, changeMap: Map<string, string>): void;
  };
};

type ActionUiState = {
  actions: ActionModel[];
  activeEvent: string;
  actionTriggerHierarchy: ActionTriggerModel[];
  selectedAction?: ActionModel | null;
  selectActionTrigger?: ActionTriggerModel | null;
  hotReload: string;
};

export const useActionViewModel = () => {
  const navigate = useNavigate();
  const [uiState, setUiState] = useState({} as ActionUiState);
  const { toast } = useToast();

  async function getActions(frameId: string) {
    if (!frameId) return;
    const result: ResultModel<ActionModel[]> = await actionRepository.getActions(frameId);

    if (result.onSuccess) {
      setUiState((prevState) => {
        return {
          ...prevState,
          actions: result.onSuccess ?? [],
          selectedAction: prevState.selectedAction,
          actionTriggerHierarchy: buildTree(
            result.onSuccess?.find((act) => {
              return act.id === prevState.selectedAction?.id && act.event === prevState.activeEvent;
            })?.triggers ?? []
          )
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function addAction(frameId: string, key: string, event: string) {
    const result: ResultModel<any> = await actionRepository.addAction(frameId, key, event);
    if (result.onSuccess) {
      getActions(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  function selectAction(frameId: string, blockKey: string, activeEvent: string, action?: ActionModel | null) {
    setUiState((prevState) => {
      return {
        ...prevState,
        activeEvent: activeEvent,
        selectedAction: action,
        actionTriggerHierarchy: buildTree(
          prevState.actions.find((act) => {
            return act.id === action?.id && act.event === activeEvent;
          })?.triggers ?? []
        )
      };
    });
    navigate(`action?frameId=${frameId}&blockKey=${blockKey}`);
  }

  async function deleteAction(frameId: string, actionId: string) {
    if (!frameId) return;
    const result: ResultModel<any> = await actionRepository.deleteAction(frameId, actionId);
    if (result.onSuccess) {
      getActions(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function addActionTrigger(
    frameId: string,
    integrationId: string,
    name: string,
    parentId: string | null,
    then: string
  ) {
    if (!frameId) return;
    const result: ResultModel<any> = await actionRepository.addActionTrigger(
      frameId,
      uiState.selectedAction?.id ?? "",
      integrationId,
      name,
      parentId,
      then
    );
    if (result.onSuccess) {
      getActions(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function deleteActionTrigger(frameId: string, triggerId: string) {
    if (!frameId) return;
    const result: ResultModel<any> = await actionRepository.deleteActionTrigger(
      frameId,
      uiState.selectedAction?.id ?? "",
      triggerId
    );
    if (result.onSuccess) {
      getActions(frameId);
      getActionTrigger(frameId, "");
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateActionTriggerProperties(frameId: string, changeMap: Map<string, string>) {
    if (!frameId) return;
    const result = await actionRepository.updateActionTriggerProperties(
      frameId,
      uiState.selectedAction?.id ?? "",
      uiState.selectActionTrigger?.id ?? "",
      changeMap
    );
    if (result.onSuccess) {
      // toast({ description: "Action trigger properties updated successfully", variant: "default" });
      getActionTrigger(frameId, uiState.selectActionTrigger?.id ?? "");
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getActionTrigger(frameId: string, actionTriggerId: string) {
    if (!frameId) return;

    if (!actionTriggerId) {
      setUiState((prevState) => {
        return {
          ...prevState,
          selectActionTrigger: null
        };
      });
    }

    const result: ResultModel<ActionTriggerModel> = await actionRepository.getActionTrigger(
      frameId,
      uiState.selectedAction?.id ?? "",
      actionTriggerId
    );

    if (result.onSuccess) {
      setUiState((prevState) => {
        return {
          ...prevState,
          selectActionTrigger: result.onSuccess
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function upgradeActionTrigger(frameId: string, actionTriggerId: string, integrationId: string) {
    const result = await actionRepository.upgradeActionTrigger(
      frameId,
      uiState.selectedAction?.id ?? "",
      actionTriggerId,
      integrationId
    );
    if (result.onSuccess) {
      getActions(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateActionTriggerPosition(frameId: string, actionTriggerId: string, parentId: string, then: string) {
    const actionId = uiState.selectedAction?.id ?? "";
    const result = await actionRepository.updateActionTriggerPosition(
      frameId,
      actionId,
      actionTriggerId,
      parentId,
      then
    );
    if (result.onSuccess) {
      getActions(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateActionTriggerName(frameId: string, actionTriggerId: string, name: string) {
    const actionId = uiState.selectedAction?.id ?? "";
    const result = await actionRepository.updateActionTriggerName(frameId, actionId, actionTriggerId, name);
    if (result.onSuccess) {
      getActions(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateActionTriggerData(frameId: string, actionTriggerId: string, changeMap: Map<string, string>) {
    const actionId = uiState.selectedAction?.id ?? "";
    const result = await actionRepository.updateActionTriggerData(frameId, actionId, actionTriggerId, changeMap);
    if (result.onSuccess) {
      getActionTrigger(frameId, actionTriggerId);
      setUiState((prevState) => {
        return {
          ...prevState,
          hotReload: Math.random().toString()
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  function findChildren(triggers: ActionTriggerModel[], parentId: string): ActionTriggerModel[] {
    return triggers
      .filter((trigger) => trigger.parentId === parentId)
      .map((trigger) => ({
        ...trigger,
        subTriggers: findChildren(triggers, trigger.id ?? "")
      }));
  }

  function buildTree(triggers: ActionTriggerModel[]): ActionTriggerModel[] {
    const roots = triggers.filter((trigger) => trigger.parentId === "");
    return roots.map((root) => ({
      ...root,
      subTriggers: findChildren(triggers, root.id ?? "")
    }));
  }

  return {
    uiState: uiState,
    uiAction: {
      getActions: getActions,
      selectAction: selectAction,
      deleteAction: deleteAction,
      addAction: addAction,
      addActionTrigger: addActionTrigger,
      deleteActionTrigger: deleteActionTrigger,
      updateActionTriggerProperties: updateActionTriggerProperties,
      getActionTrigger: getActionTrigger,
      upgradeActionTrigger: upgradeActionTrigger,
      updateActionTriggerPosition: updateActionTriggerPosition,
      updateActionTriggerName: updateActionTriggerName,
      updateActionTriggerData: updateActionTriggerData
    }
  } as ActionViewModel;
};
