import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import React, { FC, useState } from "react";
import { LocalizationViewModel } from "@/feature/localization/presentation/LocalizationViewModel";
import { Plus } from "lucide-react";

export type LocalizationAddViewProps = {
  localizationViewModel: LocalizationViewModel
};

export const LocalizationAddView: FC<LocalizationAddViewProps> = (
  props: LocalizationAddViewProps
) => {
  const [key, setKey] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button variant={"outline"} className="">
          <Plus className="h-4 w-4 me-2" />
          Add a new key
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Add a new key</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Label className="sr-only" htmlFor="key">
                  key
                </Label>
                <Input
                  id="key"
                  placeholder="Key"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setKey(event.target.value)}
                />
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.localizationViewModel.uiAction.addLocalization(key);
              }}
              variant={"default"}
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
