import { dashboardRoutes } from "@/coordinator/dashboard/DashboardRoute";
import { workspaceRoutes } from "@/coordinator/workspace/WorkspaceRoute";
import { NativeAuthProvider } from "@/feature/auth/AuthProvider";
import { authRoutes } from "@/feature/auth/AuthRoute";
import { authRepository } from "@/feature/auth/login/di/AuthComponent";
import { AppEnvironmentConfig } from "@/infrastructure/network/EnvironmentProvider";
import { Toaster } from "@/infrastructure/uikit/components/ui/toaster";
import { ThemeProvider } from "@/infrastructure/uikit/ThemeProvider";
import * as Sentry from "@sentry/react";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React, { FC } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import "./index.css";

const firebaseConfig = {
  apiKey: "AIzaSyCOXdwJkpE29mb4n5Mqg6KrJyf4nY6oT54",
  authDomain: "nativeblocks-studio.firebaseapp.com",
  projectId: "nativeblocks-studio",
  storageBucket: "nativeblocks-studio.appspot.com",
  messagingSenderId: "853246202958",
  appId: "1:853246202958:web:86f6e6c774fb55adf59f1f",
  measurementId: "G-W0YEJ8FCSH"
};
const app = initializeApp(firebaseConfig);

if (AppEnvironmentConfig.IS_PRODUCTION) {
  Sentry.init({
    dsn: AppEnvironmentConfig.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      "https://us.api.nativeblocks.io/graphql",
      "https://api.appnativeblocks.com/graphql"
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  });
  getAnalytics(app);
}

const AppRouter: FC = () => {
  return useRoutes([...authRoutes, ...dashboardRoutes, ...workspaceRoutes]);
};

const el = document.getElementById("react-app");
if (el) {
  const root = createRoot(el);
  root.render(
    <>
      <BrowserRouter>
        <ThemeProvider defaultTheme="light" storageKey="active-ui-theme">
          <NativeAuthProvider>
            <AppRouter />
            <Toaster />
          </NativeAuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

window.addEventListener("storage", () => {
  if (!authRepository.getAuthToken()) {
    window.location.href = "/login";
  }
});
