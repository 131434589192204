import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import { ArrowUpFromLine } from "lucide-react";
import React, { FC } from "react";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { LocalizationViewModel } from "@/feature/localization/presentation/LocalizationViewModel";

export type LocalizationPublishViewProps = {
  localizationViewModel: LocalizationViewModel;
};

export const LocalizationPublishView: FC<LocalizationPublishViewProps> = (
  props: LocalizationPublishViewProps
) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant={"default"} className="">
          <ArrowUpFromLine className="w-4 h-4 me-2" />
          Publish
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Publish localization</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                {`Are you sure to publish all localization keys and languages?`}
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.localizationViewModel.uiAction.publish();
              }}
              variant={"default"}
              className="w-full"
            >
              Publish
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
