import { ContributorModel } from "@/feature/organization/domain/model/OrganizationModel";
import { OrganizationViewModel } from "@/feature/organization/presentation/OrganizationViewModel";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/infrastructure/uikit/components/ui/table";
import { MoreHorizontal, Trash2 } from "lucide-react";
import React, { FC, useState } from "react";

type OrganizationContributorViewProps = {
  organizationViewModel: OrganizationViewModel;
};

export const OrganizationContributorView: FC<OrganizationContributorViewProps> = (
  props: OrganizationContributorViewProps
) => {
  return (
    <>
      <ScrollArea className="flex flex-col w-full h-screen">
        <div className="flex flex-row justify-end">
          {props.organizationViewModel.uiState.currentOrganization?.manageable ? (
            <ContributorAddView organizationViewModel={props.organizationViewModel} />
          ) : (
            <></>
          )}
        </div>
        <div className="space-y-2">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Email</TableHead>
                <TableHead>Role</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {props.organizationViewModel.uiState.currentOrganization?.contributors?.map(
                (contributor: ContributorModel) => {
                  return (
                    <TableRow key={contributor.id}>
                      <TableCell>{contributor.email}</TableCell>
                      <TableCell>{contributor.role}</TableCell>
                      <TableCell>
                        {contributor.role === "Owner" ? (
                          <></>
                        ) : (
                          <>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button variant={"ghost"} size={"icon"}>
                                  <MoreHorizontal />
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <DropdownMenuItem
                                  onSelect={(e) => {
                                    props.organizationViewModel.uiAction.deleteContributor(
                                      props.organizationViewModel.uiState.currentOrganization?.id ?? "",
                                      contributor.email
                                    );
                                  }}
                                >
                                  <Trash2 className="w-4 h-4 mx-2" />
                                  Delete {contributor.email}
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      </ScrollArea>
    </>
  );
};

export type ContributorAddViewProps = {
  organizationViewModel: OrganizationViewModel;
};

export const ContributorAddView: FC<ContributorAddViewProps> = (props: ContributorAddViewProps) => {
  const [email, setEmail] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button variant={"default"} className="">
          Add a new contributor
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Add a new contributor</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Label className="sr-only" htmlFor="email">
                  email
                </Label>
                <Input
                  id="email"
                  placeholder="Email"
                  type="email"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setEmail(event.target.value)}
                />
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.organizationViewModel.uiAction.addContributor(
                  props.organizationViewModel.uiState.currentOrganization?.id ?? "",
                  email
                );
              }}
              variant={"default"}
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
