import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import React, { FC, useState } from "react";
import { FrameViewModel } from "./FrameViewModel";
import { Switch } from "@/infrastructure/uikit/components/ui/switch";

export type FrameCreateViewProps = {
  frameViewModel: FrameViewModel;
};

export const FrameCreateView: FC<FrameCreateViewProps> = (
  props: FrameCreateViewProps
) => {
  const [name, setName] = useState("");
  const [route, setRoute] = useState("");
  const [isStarter, setIsStarter] = useState(false);
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button variant={"default"} className="w-full">
          Create a new frame
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Add a new frame</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Label className="sr-only" htmlFor="name">
                  Name
                </Label>
                <Input
                  id="name"
                  placeholder="name like Home"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setName(event.target.value)}
                />
                <Label className="sr-only" htmlFor="route">
                  Route
                </Label>
                <Input
                  id="route"
                  placeholder="route like /home"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setRoute(event.target.value)}
                />
                <div className="flex items-center justify-between">
                  <Label htmlFor="is-starter-mode">Is starter</Label>
                  <Switch onCheckedChange={(value: any) => {
                    setIsStarter(value);
                  }} id="is-starter-mode" />
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.frameViewModel.uiAction.addFrame(name, route, isStarter);
              }}
              variant={"default"}
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
