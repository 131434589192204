import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { projectRepository } from "../di/ProjectComponent";
import { ProjectModel } from "../domain/model/ProjectModel";
import { ProjectPlatformModel } from "../domain/model/ProjectPlatformModel";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import androidSvg from "@/infrastructure/uikit/icons/android.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import appleSvg from "@/infrastructure/uikit/icons/apple.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import reactSvg from "@/infrastructure/uikit/icons/react.svg";
import { ApiKeyModel } from "../domain/model/ApiKeyModel";

export type ProjectViewModel = {
  uiState: ProjectUiState;
  uiAction: ProjectUiAction;
};

type ProjectUiState = {
  projects: ProjectModel[];
  apiKeys?: ApiKeyModel[];
  platforms: ProjectPlatformModel[];
};

type ProjectUiAction = {
  createProject: (organizationId: string, name: string, platform: string) => void;
  deleteProject: (name: string, projectId: string, organizationId: string) => void;
  updateProject: (name: string, projectId: string, organizationId: string) => void;
  addApiKey: (name: string, projectId: string) => void;
  deleteApiKey: (name: string, projectId: string) => void;
  getProjects: (organizationId: string) => void;
  copyApiKey: (apiKey: string) => void;
  openWorkspace: (project: ProjectModel) => void;
  openProjectUsage: (projectId: string) => void;
  openProjectDocumentation: (project: ProjectModel) => void;
};

const defaultProjectUiState: ProjectUiState = {
  projects: [],
  platforms: []
};

export const useProjectViewModel = () => {
  const [uiState, setUiState] = useState(defaultProjectUiState);
  const { toast } = useToast();
  const navigate = useNavigate();

  const updateUiState = (newState: Partial<ProjectUiState>) => {
    setUiState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const platforms: ProjectPlatformModel[] = [
    {
      id: "ANDROID",
      name: "Android",
      enable: true,
      icon: androidSvg
    },
    {
      id: "IOS",
      name: "iOS",
      enable: true,
      icon: appleSvg
    }
    // {
    //   id: "REACT",
    //   name: "React",
    //   enable: true,
    //   icon: reactSvg,
    // },
  ];

  const createProject = async (organizationId: string, name: string, platform: string) => {
    const result: ResultModel<ProjectModel> = await projectRepository.createProject(organizationId, name, platform);
    if (result.onSuccess) {
      toast({
        description: `${name} has created successfully`,
        variant: "default"
      });
      getProjects(organizationId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  };

  const deleteProject = async (name: string, projectId: string, organizationId: string) => {
    const result: ResultModel<ProjectModel> = await projectRepository.deleteProject(projectId, organizationId);
    if (result.onSuccess) {
      toast({
        description: `${name} has deleted successfully`,
        variant: "default"
      });
      getProjects(organizationId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  };

  const updateProject = async (name: string, projectId: string, organizationId: string) => {
    const result: ResultModel<ProjectModel> = await projectRepository.updateProject(name, projectId, organizationId);
    if (result.onSuccess) {
      toast({
        description: `${name} has updated successfully`,
        variant: "default"
      });
      getProjects(organizationId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  };

  async function getProjects(organizationId: string) {
    if (!organizationId) {
      return;
    }
    const result: ResultModel<ProjectModel[]> = await projectRepository.getProjects(organizationId);

    if (result.onSuccess?.[0]) {
      updateUiState({
        projects: result.onSuccess.map((item) => {
          const projectItem = item;
          if (item.platform === "ANDROID") {
            projectItem.image = androidSvg;
          } else if (item.platform === "IOS") {
            projectItem.image = appleSvg;
          } else if (item.platform === "REACT") {
            projectItem.image = reactSvg;
          } else {
            null;
          }
          return projectItem;
        }),
        platforms: platforms
      });
    } else {
      updateUiState({
        projects: [],
        platforms: platforms
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  const addApiKey = async (projectId: string, name: string) => {
    const result = await projectRepository.addApiKey(projectId, name);
    if (result.onSuccess) {
      toast({
        description: `${name} has added successfully`,
        variant: "default"
      });
      getProjectApiKeys(projectId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  };

  const deleteApiKey = async (projectId: string, apiKey: string) => {
    const result = await projectRepository.deleteApiKey(projectId, apiKey);
    if (result.onSuccess) {
      toast({
        description: `apiKey has deleted successfully`,
        variant: "default"
      });
      getProjectApiKeys(projectId);
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  };

  async function getProjectApiKeys(projectId: string) {
    if (!projectId) {
      return;
    }
    const result: ResultModel<ApiKeyModel[]> = await projectRepository.getProjectApiKeys(projectId);
    if (result.onSuccess) {
      updateUiState({
        apiKeys: result.onSuccess
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  function copyApiKey(apiKey: string) {
    navigator.clipboard.writeText(apiKey);
    toast({ description: `The API key copied `, variant: "default" });
  }

  function openWorkspace(project: ProjectModel) {
    projectRepository.saveProjectApikey(project.apiKeys[0].apiKey);
    navigate(`/workspace`);
  }

  function openProjectUsage(projectId: string) {
    navigate(`/project/usage/${projectId}`);
  }

  function openProjectDocumentation(project: ProjectModel) {
    updateUiState({
      apiKeys: project.apiKeys
    });
  }

  return {
    uiState: uiState,
    uiAction: {
      createProject,
      deleteProject,
      updateProject,
      getProjects,
      addApiKey,
      deleteApiKey,
      copyApiKey,
      openWorkspace,
      openProjectUsage,
      openProjectDocumentation
    }
  } as ProjectViewModel;
};
