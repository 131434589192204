import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/infrastructure/uikit/components/ui/dropdown-menu";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/infrastructure/uikit/components/ui/table";
import { Copy, MoreHorizontal, Trash2 } from "lucide-react";
import React, { FC, useState } from "react";
import { ApiKeyModel } from "../domain/model/ApiKeyModel";
import { ProjectViewModel } from "./ProjectViewModel";

type ProjectApiKeyViewProps = {
  projectViewModel: ProjectViewModel;
  projectId: string;
};

export const ProjectApiKeyView: FC<ProjectApiKeyViewProps> = (props: ProjectApiKeyViewProps) => {
  function mask(input: string): string {
    const totalMaskLength = 10;

    if (input.length <= 10) {
      return input;
    }

    const visibleStart = input.slice(0, 5);
    const visibleEnd = input.slice(-5);

    const maskedPart = "*".repeat(totalMaskLength);

    return visibleStart + maskedPart + visibleEnd;
  }

  return (
    <>
      <ScrollArea className="flex flex-col w-full h-screen">
        <div className="flex flex-row justify-end">
          <ApiKeyAddView projectViewModel={props.projectViewModel} projectId={props.projectId} />
        </div>
        <div className="space-y-2">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>ApiKey</TableHead>
                <TableHead>Expire date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {props.projectViewModel.uiState.apiKeys?.map((apiKeyModel: ApiKeyModel) => {
                return (
                  <TableRow key={apiKeyModel.apiKey}>
                    <TableCell>{apiKeyModel.name}</TableCell>
                    <TableCell className="">
                      <div
                        onClick={() => {
                          props.projectViewModel.uiAction.copyApiKey(apiKeyModel.apiKey);
                        }}
                        className="flex flex-row max-w-96 gap-4"
                      >
                        <Copy className="" />
                        <p className="flex-1">{mask(apiKeyModel.apiKey)}</p>
                      </div>
                    </TableCell>
                    <TableCell>{apiKeyModel.expireAt}</TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant={"ghost"} size={"icon"}>
                            <MoreHorizontal />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            onSelect={(e) => {
                              props.projectViewModel.uiAction.deleteApiKey(props.projectId ?? "", apiKeyModel.apiKey);
                            }}
                          >
                            <Trash2 className="w-4 h-4 mx-2" />
                            Delete {apiKeyModel.name}
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </ScrollArea>
    </>
  );
};

export type ApiKeyAddViewProps = {
  projectViewModel: ProjectViewModel;
  projectId: string;
};

export const ApiKeyAddView: FC<ApiKeyAddViewProps> = (props: ApiKeyAddViewProps) => {
  const [name, setName] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button variant={"default"} className="">
          Add a new apiKey
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Add a new apiKey</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Label className="sr-only" htmlFor="name">
                  name
                </Label>
                <Input
                  id="name"
                  placeholder="Name"
                  type="name"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setName(event.target.value)}
                />
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.projectViewModel.uiAction.addApiKey(props.projectId ?? "", name);
              }}
              variant={"default"}
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
