import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import React, { FC, useState } from "react";
import { FrameViewModel } from "./FrameViewModel";
import { Switch } from "@/infrastructure/uikit/components/ui/switch";
import { Pencil } from "lucide-react";

export type FrameUpdateViewProps = {
  frameViewModel: FrameViewModel;
  frameId: string;
  framRoute: string;
  framName: string;
  framIsStarter: boolean;
};

export const FrameUpdateView: FC<FrameUpdateViewProps> = (
  props: FrameUpdateViewProps
) => {
  const [name, setName] = useState(props.framName);
  const [route, setRoute] = useState(props.framRoute);
  const [isStarter, setIsStarter] = useState(props.framIsStarter);

  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Pencil className="w-4 h-4 mx-2" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Update {name} frame</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Label className="sr-only" htmlFor="name">
                  Name
                </Label>
                <Input
                  defaultValue={name}
                  id="name"
                  placeholder="name like Home"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setName(event.target.value)}
                />
                <Label className="sr-only" htmlFor="route">
                  Route
                </Label>
                <Input
                  defaultValue={route}
                  id="route"
                  placeholder="route like /home"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="off"
                  autoCorrect="off"
                  onChange={(event: any) => setRoute(event.target.value)}
                />
                <div className="flex items-center justify-between">
                  <Label htmlFor="is-starter-mode">Is starter</Label>
                  <Switch
                    defaultChecked={isStarter}
                    onCheckedChange={(value: any) => {
                      setIsStarter(value);
                    }}
                    id="is-starter-mode"
                  />
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.frameViewModel.uiAction.updateFrame(
                  props.frameId,
                  name,
                  route,
                  isStarter
                );
              }}
              variant={"default"}
              className="w-full"
            >
              Update
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
