import React, { FC, useEffect } from "react";
import {
  IntegrationDataModel,
  IntegrationEventModel,
  IntegrationPropertyModel,
  IntegrationSlotModel
} from "../domain/model/integrationModel";
import { IntegrationViewModel } from "./IntegrationViewModel";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/infrastructure/uikit/components/ui/table";
import { InstallUpgradeUninstallView } from "./InstallUpgradeUninstallView";

export type IntegrationDetailViewProps = {
  integrationViewModel: IntegrationViewModel;
  organizationId: string;
  projectId: string;
  integrationId: string;
};

export const IntegrationDetailView: FC<IntegrationDetailViewProps> = (props: IntegrationDetailViewProps) => {
  useEffect(() => {
    props.integrationViewModel.uiAction.getIntegration(props.organizationId, props.integrationId);
  }, [props.integrationId]);
  useEffect(() => {
    props.integrationViewModel.uiAction.checkInstallationIntegration(
      props.organizationId,
      props.projectId,
      props.integrationId
    );
  }, [props.integrationId]);

  return (
    <ScrollArea className="flex flex-col w-full h-screen pb-32">
      <div className="space-y-2">
        <div className="flex flex-row justify-between">
          <p>{props.integrationViewModel.uiState.integration?.description}</p>
          <InstallUpgradeUninstallView
            integrationId={props.integrationId}
            integrationName={props.integrationViewModel.uiState.integration?.name ?? ""}
            integrationViewModel={props.integrationViewModel}
            organizationId={props.organizationId}
            projectId={props.projectId}
          />
        </div>
        <h5 className="text-xl pt-8">Properties</h5>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Key</TableHead>
              <TableHead>Default value</TableHead>
              <TableHead>Value picker</TableHead>
              <TableHead>Value picker category</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {props.integrationViewModel.uiState.integration?.properties?.map((property: IntegrationPropertyModel) => {
              return (
                <TableRow key={property.id}>
                  <TableCell className={property.deprecated ? `line-through` : ``}>{property.key}</TableCell>
                  <TableCell className={property.deprecated ? `line-through` : ``}>{property.value}</TableCell>
                  <TableCell className={property.deprecated ? `line-through` : ``}>{property.valuePicker}</TableCell>
                  <TableCell className={property.deprecated ? `line-through` : ``}>
                    {property.valuePickerGroup}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <h5 className="text-xl pt-8">Events</h5>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Event</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {props.integrationViewModel.uiState.integration?.events?.map((event: IntegrationEventModel) => {
              return (
                <TableRow key={event.id}>
                  <TableCell className={event.deprecated ? `line-through` : ``}>{event.event}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <h5 className="text-xl pt-8">Data</h5>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Key</TableHead>
              <TableHead>Type</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {props.integrationViewModel.uiState.integration?.data?.map((data: IntegrationDataModel) => {
              return (
                <TableRow key={data.id}>
                  <TableCell className={data.deprecated ? `line-through` : ``}>{data.key}</TableCell>
                  <TableCell className={data.deprecated ? `line-through` : ``}>{data.type}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <h5 className="text-xl pt-8">Slots</h5>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Slot</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {props.integrationViewModel.uiState.integration?.slots?.map((slot: IntegrationSlotModel) => {
              return (
                <TableRow key={slot.id}>
                  <TableCell className={slot.deprecated ? `line-through` : ``}>{slot.slot}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </ScrollArea>
  );
};
