import { OrganizationContributorView } from "@/feature/organization/presentation/OrganizationContributorView";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from "@/infrastructure/uikit/components/ui/drawer";
import { Input } from "@/infrastructure/uikit/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/infrastructure/uikit/components/ui/select";
import { Separator } from "@/infrastructure/uikit/components/ui/separator";
import { CreditCard, Users, X } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { OrganizationModel } from "../domain/model/OrganizationModel";
import { OrganizationViewModel } from "./OrganizationViewModel";

type OrganizationViewProps = {
  organizationViewModel: OrganizationViewModel;
  onSelect: (organizationId: string) => void;
};

export const OrganizationView: FC<OrganizationViewProps> = (props: OrganizationViewProps) => {
  const [name, setName] = useState("");

  useEffect(() => {
    props.organizationViewModel.uiAction.getOrganizations();
  }, []);

  useEffect(() => {
    const currentOrganizationId = props.organizationViewModel.uiState.currentOrganization?.id ?? "";
    props.onSelect(currentOrganizationId);
    // props.organizationViewModel.uiAction.getBillingPortalSession(currentOrganizationId);
  }, [props.organizationViewModel.uiState.currentOrganization]);

  return (
    <>
      <div className="flex flex-col w-96 p-4 bg-background">
        <div className="flex flex-row w-full pb-4">
          <Dialog>
            <DialogTrigger asChild>
              <Button className="w-full" variant={"default"}>
                Create a new organization
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create a new organization</DialogTitle>
                <DialogDescription className="pt-4">
                  <Input
                    value={name}
                    className="w-full"
                    type="text"
                    autoComplete="off"
                    placeholder="Name"
                    onChange={(event: any) => setName(event.target.value)}
                  />
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="sm:justify-start">
                <DialogClose asChild>
                  <Button
                    className="w-full"
                    variant={"default"}
                    onClick={() => {
                      setName("");
                      props.organizationViewModel.uiAction.createOrganization(name);
                    }}
                  >
                    Create
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        <div className="flex flex-col gap-1 w-full h-full">
          <Select
            onValueChange={(value: string) => {
              props.organizationViewModel.uiAction.changeCurrentOrganization(value);
              // props.organizationViewModel.uiAction.getBillingPortalSession(value);
            }}
            value={props.organizationViewModel.uiState.currentOrganization?.id}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={"Select organization"} />
            </SelectTrigger>
            <SelectContent>
              {props.organizationViewModel.uiState.organizations?.map((organization: OrganizationModel) => {
                return (
                  <SelectItem key={organization.id} value={organization.id}>
                    {organization.name}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
          <Separator className="my-2" />
          <Drawer dismissible={false}>
            <DrawerTrigger asChild={true}>
              {props.organizationViewModel.uiState.currentOrganization?.manageable ? (
                <Button variant="ghost" className="w-full justify-start">
                  <Users className="mr-2 h-4 w-4" /> Contributors
                </Button>
              ) : (
                <></>
              )}
            </DrawerTrigger>
            <DrawerContent className="h-screen">
              <DrawerHeader>
                <div className="flex flex-row justify-between items-center">
                  <DrawerTitle>Contributors</DrawerTitle>
                  <DrawerClose>
                    <Button variant={"ghost"} size={"icon"}>
                      <X />
                    </Button>
                  </DrawerClose>
                </div>
                <DrawerDescription>
                  <OrganizationContributorView organizationViewModel={props.organizationViewModel} />
                </DrawerDescription>
              </DrawerHeader>
            </DrawerContent>
          </Drawer>
          {props.organizationViewModel.uiState.currentOrganization?.manageable &&
          props.organizationViewModel.uiState.billingPortalUrl ? (
            <Button
              onClick={() => {
                const url = props.organizationViewModel.uiState.billingPortalUrl;
                if (url) {
                  window.open(url);
                }
              }}
              variant="ghost"
              className="w-full justify-start"
            >
              <CreditCard className="mr-2 h-4 w-4" /> Billing
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
