import { Button } from "@/infrastructure/uikit/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/infrastructure/uikit/components/ui/dialog";
import React, { FC, useState } from "react";
import { LocalizationViewModel } from "@/feature/localization/presentation/LocalizationViewModel";
import { Plus } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/infrastructure/uikit/components/ui/select";
import { AvailableLanguageModel } from "@/feature/localization/domain/model/AvailableLanguageModel";

export type LocalizationAddLanguageViewProps = {
  localizationViewModel: LocalizationViewModel
};

export const LocalizationAddLanguageView: FC<LocalizationAddLanguageViewProps> = (
  props: LocalizationAddLanguageViewProps
) => {
  const [language, setLanguage] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button variant={"outline"} className="">
          <Plus className="h-4 w-4 me-2" />
          Add a new language
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle asChild>
            <span>Add a new key</span>
          </DialogTitle>
          <DialogDescription>
            <div className="flex flex-col space-y-4 pt-4">
              <div className="flex flex-col gap-2">
                <Select
                  onValueChange={(value: string) => {
                    setLanguage(value);
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={"Select language"} />
                  </SelectTrigger>
                  <SelectContent>
                    {props.localizationViewModel.uiState.availableLanguages?.map(
                      (availableLanguage: AvailableLanguageModel) => {
                        return (
                          <SelectItem
                            key={availableLanguage.code}
                            value={availableLanguage.code}
                          >
                            <div className="flex flex-row">
                              {`${availableLanguage.name} [${availableLanguage.code}]`}
                            </div>
                          </SelectItem>
                        );
                      }
                    )}
                  </SelectContent>
                </Select>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              onClick={() => {
                props.localizationViewModel.uiAction.addLanguage(language);
              }}
              variant={"default"}
              className="w-full"
            >
              Add
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
