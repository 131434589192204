import { graphqlClient } from "@/infrastructure/network/NetworkComponent";
import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { IntegrationModel, IntegrationProjectModel } from "../domain/model/integrationModel";
import { IntegrationRepository } from "../domain/repository/IntegrationRepository";
import { integrationToModel } from "./IntegrationMapper";
import {
  CHECK_INTEGRATION_QUERY,
  INSTALL_INTEGRATION_MUTATION,
  INTEGRATION_QUERY,
  INTEGRATIONS_INSTALLED_QUERY,
  INTEGRATIONS_QUERY,
  UNINSTALL_INTEGRATION_MUTATION,
  UPGRADE_INTEGRATION_MUTATION
} from "./IntegrationQuery";

export class IntegrationRepositoryImpl implements IntegrationRepository {
  private readonly graphqlClient: any;

  constructor(graphqlClient: any) {
    this.graphqlClient = graphqlClient;
  }

  async getIntegrationsInstalled(
    organizationId: string,
    projectId: string,
    kind: string
  ): Promise<ResultModel<IntegrationProjectModel[]>> {
    try {
      const result = await graphqlClient.query({
        query: INTEGRATIONS_INSTALLED_QUERY,
        variables: {
          organizationId: organizationId,
          projectId: projectId,
          kind: kind
        }
      });

      const list = result.data.integrationsInstalled?.map((item: any) => {
        return {
          id: item.id,
          projectId: item.projectId,
          integrationName: item.integrationName,
          integrationKeyType: item.integrationKeyType,
          integrationId: item.integrationId,
          integrationVersion: item.integrationVersion,
          integrationDeprecated: item.integrationDeprecated ?? false,
          integrationDeprecatedReason: item.integrationDeprecatedReason ?? "",
          integrationImage: item.integrationImage,
          hasUpdate: item.hasUpdate
        };
      });

      return {
        onSuccess: list
      } as ResultModel<IntegrationProjectModel[]>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<IntegrationProjectModel[]>;
    }
  }

  async checkInstallationIntegration(
    organizationId: string,
    projectId: string,
    integrationId: string
  ): Promise<ResultModel<IntegrationProjectModel>> {
    try {
      const result = await graphqlClient.query({
        query: CHECK_INTEGRATION_QUERY,
        variables: {
          organizationId: organizationId,
          projectId: projectId,
          integrationId: integrationId
        }
      });

      return {
        onSuccess: {
          id: result.data.integrationCheckInstallation.id,
          projectId: result.data.integrationCheckInstallation.projectId,
          integrationName: result.data.integrationCheckInstallation.integrationName,
          integrationKeyType: result.data.integrationCheckInstallation.integrationKeyType,
          integrationId: result.data.integrationCheckInstallation.integrationId,
          integrationVersion: result.data.integrationCheckInstallation.integrationVersion,
          integrationImage: result.data.integrationCheckInstallation.integrationImage,
          hasUpdate: result.data.integrationCheckInstallation.hasUpdate
        }
      } as ResultModel<IntegrationProjectModel>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<IntegrationProjectModel>;
    }
  }

  async install(organizationId: string, projectId: string, integrationId: string): Promise<ResultModel<any>> {
    try {
      await graphqlClient.mutate({
        mutation: INSTALL_INTEGRATION_MUTATION,
        variables: {
          input: {
            organizationId: organizationId,
            projectId: projectId,
            integrationId: integrationId
          }
        }
      });

      return {
        onSuccess: {}
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<any>;
    }
  }

  async unInstall(organizationId: string, projectId: string, integrationId: string): Promise<ResultModel<any>> {
    try {
      await graphqlClient.mutate({
        mutation: UNINSTALL_INTEGRATION_MUTATION,
        variables: {
          input: {
            organizationId: organizationId,
            projectId: projectId,
            integrationId: integrationId
          }
        }
      });

      return {
        onSuccess: {}
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<any>;
    }
  }

  async upgradeIntegration(
    organizationId: string,
    projectId: string,
    integrationId: string
  ): Promise<ResultModel<any>> {
    try {
      await graphqlClient.mutate({
        mutation: UPGRADE_INTEGRATION_MUTATION,
        variables: {
          input: {
            organizationId: organizationId,
            projectId: projectId,
            integrationId: integrationId
          }
        }
      });

      return {
        onSuccess: {}
      } as ResultModel<any>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<any>;
    }
  }

  async getIntegration(organizationId: string, integrationId: string): Promise<ResultModel<IntegrationModel>> {
    try {
      const result = await graphqlClient.query({
        query: INTEGRATION_QUERY,
        variables: {
          organizationId: organizationId,
          integrationId: integrationId
        }
      });

      return {
        onSuccess: integrationToModel(result.data.integration)
      } as ResultModel<IntegrationModel>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<IntegrationModel>;
    }
  }

  async getIntegrations(
    organizationId: string,
    kind: string,
    platformSupport: string,
    page: number,
    limit: number
  ): Promise<ResultModel<IntegrationModel[]>> {
    try {
      const result = await graphqlClient.query({
        query: INTEGRATIONS_QUERY,
        variables: {
          organizationId: organizationId,
          kind: kind,
          platformSupport: platformSupport,
          page: page,
          limit: limit
        }
      });

      const list =
        result.data.integrations?.map((integration: any) => {
          return integrationToModel(integration);
        }) ?? [];

      return {
        onSuccess: list
      } as ResultModel<IntegrationModel[]>;
    } catch (error: any) {
      return {
        onError: error.message
      } as ResultModel<IntegrationModel[]>;
    }
  }
}
